import React, { Component } from "react";
import Clock from 'react-live-clock';
import moment from 'moment'
import 'moment/locale/es.js';
import clock from './assets/loader.gif'
const total_gmv = 0;

function Spancolor(props) {
  const precentage = props.precentage;
  const color = props.color.color;
  const icon = props.color.icon;
  return (
    <span style={{color:`${color}`}}>
      <i className={icon} aria-hidden='true'></i>&nbsp;{Math.abs(precentage)} %
    </span>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataga: [],
      id: 0,
      totalGMV:0,
      totalCompras:0,
      loadingInfo:"none",
      loadingInfoMain:"",
      message: null,
      intervalIsSet: false,
      idToDelete: null,
      idToUpdate: null,
      objectToUpdate: null
    };
    this.getDataFromDb = this.getDataFromDb.bind(this);
  }
  // initialize our state


  // when component mounts, first thing it does is fetch all existing data in our db
  // then we incorporate a polling logic so that we can easily see if our db has
  // changed and implement those changes into our UI
  componentDidMount() {
    this.getDataFromDb();
    this.getDataFromGa();
    setInterval(this.getDataFromDbThen, 300000);
    setInterval(this.getDataFromGaThen, 300000);
    /*if (!this.state.intervalIsSet) {
      let interval = setInterval(this.getDataFromDb, 1000);
      this.setState({ intervalIsSet: interval });
    }*/
  }

  // never let a process live forever
  // always kill a process everytime we are done using it
  componentWillUnmount() {
    if (this.state.intervalIsSet) {
      clearInterval(this.state.intervalIsSet);
      this.setState({ intervalIsSet: null });
    }
  }

  // just a note, here, in the front end, we use the id key of our data object
  // in order to identify which we want to Update or delete.
  // for our back end, we use the object id assigned by MongoDB to modify
  // data base entries

  // our first get method that uses our backend api to
  // fetch data from our data base
  getDataFromDb = () => {
    /*fetch("https://c7vy0xo3o0.execute-api.us-east-1.amazonaws.com/dev/dashboard/db")
      .then(response => response.json())
      .then(res => this.setState({ data: res }));*/
    var component = this;
    fetch("https://c7vy0xo3o0.execute-api.us-east-1.amazonaws.com/dev/dashboard/db")
    .then(function(response) {
      if (response.status === 504) {
          response.json().then(function(object) {
          console.log(object.message);
            component.getDataFromDb();
          })
      } else if (response.status === 200) {
        response.json().then(function(object) {
          console.log('succes');
          component.setState({ data: object, loadingInfo:"none"
          , loadingInfoMain:"" });
        })
      }
    })
  };

  getDataFromDbThen = () => {
    /*fetch("https://c7vy0xo3o0.execute-api.us-east-1.amazonaws.com/dev/dashboard/db")
      .then(response => response.json())
      .then(res => this.setState({ data: res }));*/
    var component = this;
    fetch("https://c7vy0xo3o0.execute-api.us-east-1.amazonaws.com/dev/dashboard/db")
    .then(function(response) {
      if (response.status === 504) {
          response.json().then(function(object) {
          console.log(object.message);
          })
      } else if (response.status === 200) {
        response.json().then(function(object) {
          console.log('succes');
          component.setState({ data: object, loadingInfo:"none"
          , loadingInfoMain:"" });
        })
      }
    })
  };

  getDataFromGa = () => {
    var component = this;
    fetch("https://c7vy0xo3o0.execute-api.us-east-1.amazonaws.com/dev/dashboard/ga")
    .then(function(response) {
      if (response.status === 504) {
          response.json().then(function(object) {
          console.log(object.message);
            component.getDataFromGa();
          })
      } else if (response.status === 200) {
        response.json().then(function(object) {
          console.log('succes');
          component.setState({ dataga: object});
        })
      }
    })
  };

  getDataFromGaThen = () => {
    var component = this;
    fetch("https://c7vy0xo3o0.execute-api.us-east-1.amazonaws.com/dev/dashboard/ga")
    .then(function(response) {
      if (response.status === 504) {
          response.json().then(function(object) {
          console.log(object.message);
          })
      } else if (response.status === 200) {
        response.json().then(function(object) {
          console.log('succes');
          component.setState({ dataga: object});
        })
      }
    })
  };



  toFixedTrunc = (value, n) => {
  const f = Math.pow(10, n);
  return (Math.trunc(value*f)/f).toFixed(n);
  }

  // our update method that uses our backend api
  // to overwrite existing data base information
  kFormatter = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
  };

  calculateOrdersPercentage = (present_orders, past_orders) => {
    let diff = parseInt(present_orders) - parseInt(past_orders);
    let percentage = (diff/parseInt(past_orders))*100;
    return isNaN(this.toFixedTrunc(percentage,1))?'-':isFinite(this.toFixedTrunc(percentage,1))?this.toFixedTrunc(percentage,1):'0.0';
  };

  calculateOrdersPercentageColor = (present_orders, past_orders) => {
    let diff = parseInt(present_orders) - parseInt(past_orders);
    let percentage = (diff/parseInt(past_orders))*100;
    if (percentage>0){return {color:"#90c564",icon: "fa fa-caret-up"};}else{ return {color:"#e3524f",icon: "fa fa-caret-down"};}
  };

  calculateConversionPercentage = (present_orders, past_orders) => {
    let diff = parseFloat(present_orders) - parseFloat(past_orders);
    let percentage = (diff/parseFloat(past_orders))*100;
    return isNaN(this.toFixedTrunc(percentage,1))?'-':isFinite(this.toFixedTrunc(percentage,1))?this.toFixedTrunc(percentage,1):'0.0';
  };

  calculateConversionPercentageColor = (present_orders, past_orders) => {
    let diff = parseFloat(present_orders) - parseFloat(past_orders);
    let percentage = (diff/parseFloat(past_orders))*100;
    if (percentage>0){return {color:"#90c564",icon: "fa fa-caret-up"};}else{ return {color:"#e3524f",icon: "fa fa-caret-down"};}
  };

  calculateGmvPercentage = (present_orders, past_orders) => {
    let diff = parseFloat(present_orders) - parseFloat(past_orders);
    let percentage = (diff/parseFloat(past_orders))*100;
    return isNaN(this.toFixedTrunc(percentage,1))?'-':isFinite(this.toFixedTrunc(percentage,1))?this.toFixedTrunc(percentage,1):'0.0';
  };

  calculateGmvPercentageColor = (present_orders, past_orders) => {
    let diff = parseFloat(present_orders) - parseFloat(past_orders);
    let percentage = (diff/parseFloat(past_orders))*100;
    if (percentage>0){return {color:"#90c564",icon: "fa fa-caret-up"};}else{ return {color:"#e3524f",icon: "fa fa-caret-down"};}
  };

  totalGMV = (data) => {
    console.log('totalGMV');
    let total = 0;
      data.order_and_gmv.map(dat => (
        dat.name!=="office"?
              total = total +  parseFloat(dat.data.present_gmv):0
        ))
    return this.kFormatter(total);
  };

  totalCompras = (data) => {
    let total = 0;
    data.order_and_gmv.map(dat => (
          dat.name!=="office"?
            total = total +  parseFloat(dat.data.present_orders):0
        ))
    return this.kFormatter(total);
  };

  totalConv = (dataga) => {
    let total = 0;
    dataga.conversion.map(dat => (
      dat.name ?
      total = total + parseFloat(dat.data.conversion_today)
      :''));
    return this.toFixedTrunc(total,2);
  };


  swap = (theArray, indexA, indexB) => {
      var temp = theArray[indexA];
      theArray[indexA] = theArray[indexB];
      theArray[indexB] = temp;
      return theArray;
  }
  // here is our UI
  // it is easy to understand their functions when you
  // see them render into our screen
  render() {
    const { data, dataga } = this.state;
    return (
      <div>
      <div className="centered" style={{display:this.state.loadingInfo}}>
        <i style={{color:"#f6c12c"}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </div>
    <main style={{display:this.state.loadingInfoMain}}>
      <section className="cards">
        <article>
          <div className="card-header">
            <span className="header-card-text">ClickBus México</span> <br />
          </div>
          <img width="73%" src="https://cdn6.aptoide.com/imgs/4/d/a/4da1b0daed02e74490cc4baf6fc6c33d_icon.png?w=160&amp;imagerefresh=1548795167589" alt="ClickBus Mexico"/>
        </article>
        <article>
          <div className="card-header">
          <span className="header-card-text">Horario</span> <br />
          </div>
          <div className="card-middle">
            <span style={{ color: "#fff", fontSize:"smaller" }}><Clock  ticking={true} format={'HH:mm'}/></span>
          </div>
          <div className="card-end-w">
           <span>{moment().format('dddd D MMM YYYY')} </span>
          </div>
        </article>
        {!dataga.hasOwnProperty("session") || dataga.session.length <= 0 
          ? <article className="article-icon-loading hidden"><i className="fa fa-spinner fa-spin fa-3x fa-fw icon-loading"></i></article>
          : dataga.session.map(dat => (
            dat.name ?
              <article key={dat.name}>
              <div className="card-header">
                <span className="header-card-text">Sesiones: {dat.name}</span> <br />
              </div>
              <div className="card-middle">
                <span style={{ color: "#fff", fontSize:"smaller" }}>{dat.data.current_session}</span>
              </div>
              <div className="card-end">
                <span>&nbsp;</span>
              </div>
            </article>
            :''
        ))}
      </section>
      {/* SECCION COMPRAS*/}
      <section className="cards">
        {/*<article>
          <div className="card-header">
            <span style={{ color: "#fff", fontSize:"smaller" }}>Compras: Hoy</span> <br />
          </div>
          <div className="card-middle">
            <span style={{ color: "#fff", fontSize:"smaller" }}>{data.length <= 0 ? '0': this.totalCompras(data)}</span>
          </div>
          <div className="card-end">
           <span></span>
          </div>
        </article>*/}
        {data.length <= 0
          ? <article className="article-icon-loading"><i className="fa fa-spinner fa-spin fa-3x fa-fw icon-loading"></i></article>
          : this.swap(data.order_and_gmv,0,5).map(dat => (
            dat.name!=="Mobile-app"?
          <article key={dat.name}>
            <div className="card-header">
              <span className="header-card-text">{dat.name=="office"?"Call-Center":dat.name}</span> <br />

            </div>
            <div className="card-middle">
            <span style={{ color: "#fff", fontSize:"smaller" }}>{dat.data.present_orders}</span>
            </div>
            <div className="card-end">
              <Spancolor
                precentage={this.calculateOrdersPercentage(dat.data.present_orders,dat.data.past_orders)}
                color={this.calculateOrdersPercentageColor(dat.data.present_orders,dat.data.past_orders)}
              />
            </div>
          </article>:''
        ))}
        </section>
        {/* SECCION GMV*/}
        <section className="cards">
          {/*<article>
            <div className="card-header">
              <span style={{ color: "#fff", fontSize:"smaller" }}>GMV: Hoy</span> <br />
            </div>
            <div className="card-middle">
              <span style={{ color: "#fff", fontSize:"smaller" }}>{data.length <= 0 ? '0': this.totalGMV(data)}</span>
            </div>
            <div className="card-end">
             <span></span>
            </div>
          </article>*/}
          {data.length <= 0
            ? <article className="article-icon-loading"><i className="fa fa-spinner fa-spin fa-3x fa-fw icon-loading"></i></article>
            : data.order_and_gmv.map(dat => (
              dat.name!=="Mobile-app"?
                <article key={dat.name}>
                  <div className="card-header">
                    <span className="header-card-text">GMV: {dat.name=="office"?"Call-Center":dat.name}</span> <br />

                  </div>
                  <div className="card-middle">
                    <span style={{fontSize:"1.75rem", color:"#fff"}}>$MX&nbsp;</span>
                    <span style={{ color: "#fff", fontSize:"smaller" }}>{this.kFormatter(dat.data.present_gmv)}</span>
                  </div>
                  <div className="card-end">
                    <Spancolor
                      precentage={this.calculateGmvPercentage(dat.data.present_gmv,dat.data.past_gmv)}
                      color={this.calculateGmvPercentageColor(dat.data.present_gmv,dat.data.past_gmv)}
                    />
                  </div>
                </article>:''
          ))}
        </section>
      {/* SECCION CONVERSION*/}
        <section className="cards">
          <article>
            <div className="span-position-card">
              <div className="card-header">
                <span className="header-card-text">GMV: Mes</span> <br />
              </div>
              <div className="card-middle">
                 <span style={{fontSize:"1.75rem", color:"#fff"}}>$MX&nbsp;</span>
                 <span style={{ color: "#fff", fontSize:"smaller" }}>{data.length <= 0 ? '0': this.kFormatter(data.month_gmv)}</span>
              </div>
              <div className="card-end-w">
               <span>-</span>
              </div>
            </div>
          </article>
          {!dataga.hasOwnProperty("session") || dataga.session.length <= 0 
            ? <article className="article-icon-loading hidden"><i className="fa fa-spinner fa-spin fa-3x fa-fw icon-loading"></i></article>
            : dataga.conversion.map(dat => (
              dat.name ?
                <article key={dat.name}>
                  <div className="card-header">
                    <span className="header-card-text">Conversion: {dat.name}</span> <br />
                  </div>
                  <div className="card-middle">
                    <span style={{ color: "#fff", fontSize:"smaller" }}>{dat.data.conversion_today}</span>
                  </div>
                  <div className="card-end">
                    <Spancolor
                      precentage={this.calculateConversionPercentage(dat.data.conversion_today,dat.data.conversion_last_week)}
                      color={this.calculateConversionPercentageColor(dat.data.conversion_today,dat.data.conversion_last_week)}
                    />
                  </div>
                </article>
              :''
          ))}
          {/*<article>
            <div className="span-position-card">
              <div className="card-header">
                <span className="header-card-text">Total Conversion</span> <br />
              </div>
              <div className="card-middle">
                 <span style={{fontSize:"1.75rem", color:"#fff"}}></span>
                 <span style={{ color: "#fff", fontSize:"smaller" }}>{dataga.length <= 0 ? '0': this.totalConv(dataga)}</span>
              </div>
              <div className="card-end-w">
               <span>-</span>
              </div>
            </div>
          </article>*/}
        </section>
</main>
  </div>
    );
  }
}

export default App;
